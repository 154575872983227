/**
 * 採用情報の一覧ページや一覧ブロックのティーザー
 */

import * as React from "react"
import PropTypes from "prop-types"
import { Link, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import isNil from "lodash/isNil"

import truncate from "../../utils/truncate"

const ABSTRACT_MAX_LENGTH = 70

const Teaser = ({ job }) => {
  const meta_description = truncate(ABSTRACT_MAX_LENGTH, job.meta_description)
  return (
    <Link to={job.path} className="block text-textBase no-underline pc:mb-8 pc:overflow-hidden tablet_sp:mb-6">
      <Thumbnail src={job?.thumbnail?.publicURL} alt={job.title} className="pc:float-left pc:mr-4 pc:mb-3 tablet_sp:float-left tablet_sp:mr-4 tablet_sp:mb-3" />
      <h3 className="mt-0 pc:mb-2 pc:text-lg tablet_sp:text-sm">{job.title}</h3>
      <div className="mb-2 break-words tablet_sp:clear-both tablet_sp:text-xs tablet_sp:leading-normal">{meta_description}</div>
    </Link>
  )
}

Teaser.propTypes = {
  job: PropTypes.exact({
    title: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    meta_description: PropTypes.string,
    thumbnail: PropTypes.exact({
      publicURL: PropTypes.string.isRequired,
    }),
  }),
}

/**
 * ヘルパー: サムネール画像
 */
const Thumbnail = ({ src, alt, className }) => {
  if (!isNil(src)) {
    return (
      <img src={src} alt={alt} className={className} width={160}
        style={{
          width: `160px`,
          aspectRatio: `16 / 9`,
          objectFit: `contain`,
        }}
      />
    )
  }

  // `src` が `null` の場合はフォールバック画像を表示する
  return (
    <StaticImage
      src="../../images/tbjob/thumbnail.png"
      alt={alt}
      width={160}
      aspectRatio={16 / 9}
      className={className}
    />
  )
}

Thumbnail.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
}

// フラグメントもあわせて提供する
export const query = graphql`
  fragment TbJobTeaser on TbJob {
    title
    path
    meta_description
    thumbnail {
      publicURL
    }
  }
`

export default Teaser
