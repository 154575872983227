import * as React from "react"
import { graphql } from "gatsby"
import Markdown from "../components/markdown"

import HeaderImage from "../components/tbpage/header-image"
import Layout from "../components/layout"
import PageBottomBlock from "../components/tbpage/page-bottom-block"
import PostList from "../components/tbpage/post-list"
import ProductList from "../components/tbpage/product-list"

const Template = ({ data }) => {
  const {
    node,
    posts_first,
    posts_second,
    products_first,
    jobs,
    header_image_pc,
    header_images_sp,
  } = data
  const {
    post_area_first,
    post_area_second,
    product_category_first,
  } = node

  const breadcrumbs = node.breadcrumbs.map((item) => {
    return {
      label: item.title,
      href: item.path,
    }
  })

  return (
    <Layout
      title={node.title}
      description={node.meta_description}
      keywords={node.meta_keywords}
      image={node.ogp_image?.publicURL}
      breadcrumbs={breadcrumbs}
      CustomHeader={(
        <HeaderImage
          pcImage={header_image_pc}
          spImages={header_images_sp.nodes}
        />
      )}
    >
      {/* 固定ページでは h1 を表示しない */}
      <h1 className="hidden">{node.title}</h1>
      <PostList postArea={post_area_first} posts={posts_first} />
      <section className="pc:mb-16 tablet_sp:mb-8">
        <Markdown>{node.body ?? ``}</Markdown>
      </section>
      <PostList postArea={post_area_second} posts={posts_second} />
      <ProductList productCategory={product_category_first} products={products_first} />

      {/* 「キャリア採用」ページに求人情報一覧を追加 */}
      <PageBottomBlock jobs={jobs} />
    </Layout>
  )
}

export default Template

export const pageQuery = graphql`
  query(
    $path: String!,
    $post_area_first: String,
    $post_area_second: String,
    $product_category_first: String,
    $header_image_dir: String!,
    $has_jobs_block: Boolean!,
  ) {
    node: tbPage(path: { eq: $path }) {
      title
      slug
      ogp_image {
        publicURL
      }
      meta_description
      meta_keywords
      file {
        publicURL
      }
      body
      breadcrumbs {
        title
        path
      }
      post_area_first {
        path
        heading_label
      }
      post_area_second {
        path
        heading_label
      }
      product_category_first {
        path
        heading_label
      }
    }

    # 記事一覧（上部）
    posts_first: allTbPost(
      filter: {post_areas: {elemMatch: {machine_name: {eq: $post_area_first}}}},
      limit: 3,
      sort: {fields: [date, cms_id], order: [DESC, DESC]}
    ) {
      nodes {
        ...TbPostTeaser
      }
      pageInfo {
        totalCount
      }
    }

    # 記事一覧（下部）
    posts_second: allTbPost(
      filter: {post_areas: {elemMatch: {machine_name: {eq: $post_area_second}}}},
      limit: 3,
      sort: {fields: [date, cms_id], order: [DESC, DESC]}
    ) {
      nodes {
        ...TbPostTeaser
      }
      pageInfo {
        totalCount
      }
    }

    # 商品一覧
    products_first: allTbProduct(
      filter: {product_categories: {elemMatch: {machine_name: {eq: $product_category_first}}}}
      limit: 10,
      sort: {fields: [order_weight, cms_id], order: [ASC, DESC]}
    ) {
      nodes {
        ...TbProductTeaser
      }
      pageInfo {
        totalCount
      }
    }

    # 採用情報一覧
    # 全求人を表示する
    jobs: allTbJob(
      sort: {fields: [date_posted, cms_id], order: [DESC, DESC]}
    ) @include(if: $has_jobs_block) {
      nodes {
        ...TbJobTeaser
      }
    }

    # ヘッダー画像（ PC ）
    header_image_pc: file(
      relativeDirectory: {eq: $header_image_dir}
      name: {eq: "P1"}
      internal: {mediaType: {glob: "image/*"}}
      sourceInstanceName: {eq: "images"}
    ) {
      src: publicURL
    }

    # ヘッダー画像（ SP / Tablet ）
    header_images_sp: allFile(
      filter: {
        relativeDirectory: {eq: $header_image_dir}
        name: {glob: "S*"}
        internal: {mediaType: {glob: "image/*"}}
        sourceInstanceName: {eq: "images"}
      }
      sort: {order: ASC, fields: name}
    ) {
      nodes {
        src: publicURL
      }
    }
  }
`
