/**
 * 固定ページ内の採用情報一覧
 */

import * as React from "react"
import PropTypes from "prop-types"

import Teaser from "../tbjob/teaser"
import BorderedHeading from "./bordered-heading"

const JobList = ({ jobs }) => {
  // 件数 0 件の場合は見出しも含めて何も表示しない
  if (jobs.nodes.length < 1) {
    return ``
  }

  return (
    <section className="pc:mb-16 tablet_sp:mb-8">
      <BorderedHeading>求人情報</BorderedHeading>

      <div>
        {jobs.nodes.map((job) => (<Teaser key={job.path} job={job} />))}
      </div>
    </section>
  )
}

JobList.propTypes = {
  jobs: PropTypes.exact({
    nodes: PropTypes.array.isRequired,
  }).isRequired,
}

export default JobList
