/**
 * 固定ページ内の下部に表示されるブロック
 *
 * 現在のページのパスによって出し分けする
 */

import * as React from "react"
import PropTypes from "prop-types"
import isNil from "lodash/isNil"

import JobList from "./job-list"

const PageBottomBlock = ({ jobs }) => {
  // ここで `useStaticQuery()` を使うとエラーが出ることがあるので
  // `jobs` のデータは `TbPage.js` 側で取得しておいて引数で渡しています
  if (!isNil(jobs)) {
    return <JobList jobs={jobs} />
  }

  return ``
}

PageBottomBlock.propTypes = {
  jobs: PropTypes.shape({
    nodes: PropTypes.array.isRequired,
  }),
}

export default PageBottomBlock
